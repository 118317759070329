import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/img/logo/logoC.png'

import callIcon from '../assets/img/icons/call.png';
import pinIcon from '../assets/img/icons/pin.png';
import searchIcon from '../assets/img/icons/search.png'



function Navigation() {

    let pathname = window.location.pathname

    const [class1,setClass1] = useState(false)
    const [class2,setClass2] = useState(false)
    const [class3,setClass3] = useState(false)
    const [class4,setClass4] = useState(false)
    const [class5,setClass5] = useState(false)
    const [class6,setClass6] = useState(false)

    useEffect(() => {
        if(pathname.toLowerCase() === "/"){
            setClass1(true);
            setClass2(false);
            setClass3(false);
            setClass4(false);
            setClass5(false);
            setClass6(false);
        }else if(pathname.toLowerCase() === "/services"){
            setClass1(false);
            setClass2(false);
            setClass3(true);
            setClass4(false);
            setClass5(false);
            setClass6(false);
        }else if(pathname.toLowerCase() === "/about"){
            setClass1(false);
            setClass2(true);
            setClass3(false);
            setClass4(false);
            setClass5(false);
            setClass6(false);
        }else if(pathname.toLowerCase() === "/contact"){
            setClass1(false);
            setClass2(false);
            setClass3(false);
            setClass4(false);
            setClass5(false);
            setClass6(true);
        }else if(pathname.toLowerCase() === "/faq"){
            setClass1(false);
            setClass2(false);
            setClass3(false);
            setClass4(false);
            setClass5(true);
            setClass6(false);
        }else if(pathname.toLowerCase() === "/logistic"){
            setClass1(false);
            setClass2(false);
            setClass3(false);
            setClass4(true);
            setClass5(false);
            setClass6(false);
        }
    
    }, [pathname])
   
    return (
        <>
            <section className="navigation">
                <div className="navigation__info">
                    <div className="navigation__logoBox">
                        <img src={logo} alt="" className="navigation__logoImg"/>
                    </div>
                    <div className="navigation__info-container">
                        <div className="navigation__info-content">
                            <img src={callIcon} alt="" className="navigation__info-icon"/>
                            <div className="navigation__info-text">
                                <p className="navText-regular"  style={{marginBottom: "10px"}}>+977 9842157896</p>
                                <p className="navText-light">info@saioverseas.com</p>
                            </div>
                        </div>
                        <div className="navigation__info-content">
                            <img src={pinIcon} alt="" className="navigation__info-icon"/>
                            <div className="navigation__info-text">
                                <p className="navText-regular" style={{marginBottom: "10px"}}>+977 9842157896</p>
                                <p className="navText-light">info@saioverseas.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
                <label htmlFor="navi-toggle" className="navigation__button">
                    <span className="navigation__icon">&nbsp;</span>
                </label>
                <div class="navigation__background">&nbsp;</div>

                <nav className="navigation__links">
                    <div className="navigation__links-container">
                        <Link exact to="/" className={class1 ? "navigation__link navigation__link-active" : "navigation__link"}>Home</Link>
                        <Link exact to="/about" className={class2 ? "navigation__link navigation__link-active" : "navigation__link"}>About</Link>
                        <Link exact to="/services" className={class3 ? "navigation__link navigation__link-active" : "navigation__link"}>Services</Link>
                        <Link exact to="/logistic" className={class4 ? "navigation__link navigation__link-active" : "navigation__link"}>Logistic</Link>
                        <Link exact to="/faq" className={class5 ? "navigation__link navigation__link-active" : "navigation__link"}>FAQ</Link>
                        <Link exact to="/contact" className={class6 ? "navigation__link navigation__link-active" : "navigation__link"}>Contact us</Link>
                    </div>
                    <div className="navigation__searchBox">
                        <input type="text" placeholder="Search now" name="search" className="navigation__searchInput"/>
                        <button type="submit" className="navigation__searchBtn"><img src={searchIcon} alt="" className="navigation__searchIcon"/></button>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Navigation
