import React from 'react'

import bgImg from '../assets/img/Breadcrumbs.png'
import { Link } from 'react-router-dom';
function Breadcrumbs() {
    let pathname = window.location.pathname
    
    let subName = "";

    if(pathname.toLowerCase() === "/services"){
        subName = "Services"
    }else if(pathname.toLowerCase() === "/about"){
        subName = "About"
    }else if(pathname.toLowerCase() === "/contact"){
        subName = "Contact"
    }else if(pathname.toLowerCase() === "/faq"){
        subName = "FaQ"
    }else if(pathname.toLowerCase() === "/logistic"){
        subName = "Logistic"
    }


    return (
        <>
            <section className="breadcrumbs">
                <div className="breadcrumbs__links">
                    <Link exact to="/" className="breadcrumbs__link" style={{marginRight: "5px"}}>Home</Link>
                    <p className="breadcrumbs__link" style={{marginRight: "5px", cursor: "default"}}>/</p>
                    <p className="breadcrumbs__link breadcrumbs__link-active">{subName}</p>
                </div>
            </section>
        </>
    )
}

export default Breadcrumbs
