import React, { useState, useEffect } from 'react'
import Heading from './Heading';

function DynamicDesc() {

    const [ activeState, setActiveState ] = useState({
        activeObject: 0,
        objects: [{ label: "About Us" }, { label: "Customized agreement" }, { label: "Transparency" }, { label: "Customer-client Confidentiality" }, { label: "Project Sign Up" }]
    });

    const toggleActive = (index) => {

        setActiveState({ ...activeState, activeObject: activeState.objects[index] });
    }

    const toggleActiveStyles = (index) => {
        if(activeState.objects[index] === activeState.activeObject) {
            return "dynamicDesc__btn dynamicDesc__btn-active"
        } else {
            return "dynamicDesc__btn"
        }
    }

    useEffect(() => {
        toggleActive(0);
    },activeState)


    return (
        <section className="dynamicDesc">
            <div className="dynamicDesc__tabs">
                <div className="dynamicDesc__btn-container">
                    {
                        activeState.objects.map((el,index) => (
                            <div className="dynamicDesc__btn-wrapper" key={index} onClick={() => {toggleActive(index)}}>
                                <button className={toggleActiveStyles(index)}>
                                    {el.label}
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="dynamicDesc__content">
                <Heading regular="We are " bold="Sai Overseas" addClass="margin-bottom-small"/>
                <p className="paragraph-light margin-bottom-small">OUR ACCOMPLISHMENTS ARE WHO WE ARE.</p>

                <div className="dynamicDesc__content-wrapper">
                    <p className="paragraph-extraLight">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We 
                    </p>

                    <p className="paragraph-extraLight">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We 
                    </p>

                    <p className="paragraph-extraLight">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We 
                    </p>
                    
                    <p className="paragraph-color" style={{cursor: "pointer"}}>
                    Know more about our services and deliverables on corporate gifting &nbsp; 

                    <svg xmlns="http://www.w3.org/2000/svg" width="34.249" height="16" viewBox="0 0 34.249 16" >
                        <path id="ic_arrow_back_24px" d="M-14.249,11H16.17L10.58,5.41,12,4l8,8-8,8-1.41-1.41L16.17,13H-14.249Z" transform="translate(14.249 -4)" fill="#eb3f57"/>
                    </svg>
                    
                    </p>
                </div>
               
            </div>
        </section>
    )
}

export default DynamicDesc
