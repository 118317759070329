import React from 'react'

function Heading(props) {
    return (
        <>
            <div className={`heading__wrapper ${props.addClass}`}>
                <p>
                    <span className="heading-primary">{props.regular} </span>
                    <span className="heading-primary-bold">{props.bold}</span>
                </p>
            </div>
        </>
    )
}

export default Heading
