import React from 'react'

function Button(props) {
    return (
       <button className= {`btn ${props.addClass}`}>
           <p className="paragraph-color">
                {props.label} &nbsp;  
                <svg xmlns="http://www.w3.org/2000/svg" width="34.249" height="16" viewBox="0 0 34.249 16" >
                    <path id="ic_arrow_back_24px" d="M-14.249,11H16.17L10.58,5.41,12,4l8,8-8,8-1.41-1.41L16.17,13H-14.249Z" transform="translate(14.249 -4)" />
                </svg>
            </p>
       </button>
    )
}

export default Button
