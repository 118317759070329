import React, {useState} from 'react'
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from '../components/Footer'
import Header2 from '../components/Header2';
import Heading from '../components/Heading'
import Navigation from '../components/Navigation'
import FooterContact from './../components/FooterContact';

function Services() {

    const [class1,setClass1] = useState(false)
    const [class2,setClass2] = useState(false)
    const [class3,setClass3] = useState(false)
    const [class4,setClass4] = useState(false)
    const [class5,setClass5] = useState(false)
    const [class6,setClass6] = useState(false)
    const [class7,setClass7] = useState(false)
    const [class8,setClass8] = useState(false)

    let plusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <line id="Line_17" data-name="Line 17" x2="14" transform="translate(0 7)" fill="none" stroke="#eb3f57" stroke-width="4"/>
                    <g id="Group_92" data-name="Group 92" transform="translate(-170.5 -955.5)">
                        <line id="Line_18" data-name="Line 18" x2="14" transform="translate(177.5 955.5) rotate(90)" fill="none" stroke="#eb3f57" stroke-width="4"/>
                    </g>
                </svg>

    let minIcon =    <svg xmlns="http://www.w3.org/2000/svg" width="32.425" height="27.87" viewBox="0 0 32.425 27.87">
                        <path id="ic_flight_takeoff_24px" d="M2.9,28.242h30.38v3.2H2.9ZM34.187,13.276a2.4,2.4,0,0,0-2.942-1.695l-8.49,2.27L11.721,3.57l-3.086.815,6.62,11.464L7.308,17.976l-3.15-2.462-2.318.624L4.75,21.19l1.231,2.127,2.558-.688,8.49-2.27L23.985,18.5l8.49-2.27a2.434,2.434,0,0,0,1.711-2.958Z" transform="translate(-1.84 -3.57)" fill="#eb3f57"/>
                    </svg>



    let toggleContent = (i) => {
        if(i === 1){
            setClass1(!class1);
        }else if(i === 2){
            setClass2(!class2);
        }else if(i === 3){
            setClass3(!class3);
        }else if(i === 4){
            setClass4(!class4);
        }else if(i === 5){
            setClass5(!class5);
        }else if(i === 6){
            setClass6(!class6);
        }else if(i === 7){
            setClass7(!class7);
        }else  if(i === 8){
            setClass8(!class8);
        }
    }
    
    return (
        <>
            <div className="pages">
                <Header2/>
                <section className="servicesPage">
                    <div className="servicesPage__container">
                        <div className="servicesPage__header">
                            <Heading regular = "Our " bold = "Services" addClass = "margin-bottom-bAvg" />
                            <p className="paragraph-extraLight" >The following are some frequently asked questions.</p>
                        </div>

                        <div className="servicesPage__wrapper">
                            <div className={class1 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(1)}>
                                    <div className="servicesPage__content-heading">
                                        {class1 ? minIcon : plusIcon}
                                        <p className="services-heading">Product/supplier sourcing and trading</p>
                                    </div>
                                    <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                                </div>
                            <div className={class2 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(2)}>
                                <div className="servicesPage__content-heading">
                                    {class2 ? minIcon : plusIcon}
                                    <p className="services-heading">Supplier negotiation</p>
                                </div>
                                <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class3 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(3)}>
                                <div className="servicesPage__content-heading">
                                    {class3 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class4 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(4)}>
                                <div className="servicesPage__content-heading">
                                    {class4 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class5 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(5)}>
                                <div className="servicesPage__content-heading">
                                    {class5 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class6 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(6)}>
                                <div className="servicesPage__content-heading">
                                    {class6 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class7 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(7)}>
                                <div className="servicesPage__content-heading">
                                    {class7 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                            <div className={class8 ? "servicesPage__content servicesPage__content-active" : "servicesPage__content"} onClick={() => toggleContent(8)}>
                                <div className="servicesPage__content-heading">
                                    {class8 ? minIcon : plusIcon}
                                    <p className="services-heading">does sai offer international logistics service?</p>
                                </div>
                                 <p className="paragraph-light">
                                        Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                                    </p>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterContact/>
                <Footer/>
            </div>
        </>
    )
}

export default Services
