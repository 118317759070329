import React from 'react'
import Footer from '../components/Footer'
import FooterContact from '../components/FooterContact'

function Test() {
    return (
        <>
            <FooterContact/>
        </>
    )
}

export default Test
