import React, { useState, useEffect } from 'react'
import Heading from './Heading';

import descImg from '../assets/img/pic4.png'

function AboutDynDesc() {

    const [ activeState, setActiveState ] = useState({
        activeObject: 0,
        objects: [{ label: "About Us" }, { label: "Customized agreement" }, { label: "Transparency" }, { label: "Customer-client Confidentiality" }, { label: "Project Sign Up" }]
    });

    const toggleActive = (index) => {

        setActiveState({ ...activeState, activeObject: activeState.objects[index] });
    }

    const toggleActiveStyles = (index) => {
        if(activeState.objects[index] === activeState.activeObject) {
            return "aboutDynDesc__btn aboutDynDesc__btn-active"
        } else {
            return "aboutDynDesc__btn"
        }
    }

    useEffect(() => {
        toggleActive(0);
    },activeState)


    return (
        <section className="aboutDynDesc">
            <div className="aboutDynDesc__tabs">
                <div className="aboutDynDesc__btn-container">
                    {
                        activeState.objects.map((el,index) => (
                            <div className="aboutDynDesc__btn-wrapper" key={index} onClick={() => {toggleActive(index)}}>
                                <button className={toggleActiveStyles(index)}>
                                    {el.label}
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="aboutDynDesc__content">
                <div className="aboutDynDesc__content-wrapper">
                    <div className="aboutDynDesc__content-txt">
                        <Heading regular="We are " bold="Sai Overseas" addClass="margin-bottom-small"/>
                        <p className="paragraph-light margin-bottom-small">OUR ACCOMPLISHMENTS ARE WHO WE ARE.</p>
                        <p className="paragraph-extraLight">
                            Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We 
                        </p>
                    </div>
                    <div className="aboutDynDesc__content-picture">
                        <img src={descImg} alt="" className="aboutDynDesc__content-img"/>
                    </div>
                </div>
                
                <div className="aboutDynDesc__textContent">

                <h4 className="heading-secondary">Customized agreement</h4>
                <p className="paragraph-medium margin-bottom-small">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                </p>
                <h4 className="heading-secondary">Transparency</h4>
                <p className="paragraph-medium margin-bottom-small">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                </p>
                <h4 className="heading-secondary">Customer-client Confidentiality</h4>
                <p className="paragraph-medium margin-bottom-small">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                </p>
                <h4 className="heading-secondary">Project Sign Up</h4>
                <p className="paragraph-medium margin-bottom-small">
                    Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                </p>
                </div>
            </div>

        </section>
    )
}

export default AboutDynDesc
