import React, { useState } from 'react';
import Heading from './Heading'

import clientMapImg from '../assets/img/clientMap.png';

function ClientMap() {

    const [detail1, setdetail1] = useState("clientMap__pin-details--active")
    const [detail2, setdetail2] = useState("")
    const [detail3, setdetail3] = useState("")
    const [detail4, setdetail4] = useState("")
    const [detail5, setdetail5] = useState("")
    const [detail6, setdetail6] = useState("")
    
    let handleMouseOver = (val) => {
        if(val===1) {
            setdetail1("clientMap__pin-details--active");
            setdetail2("");
            setdetail3("");
            setdetail4("");
            setdetail5("");
            setdetail6("");
 
        }else if(val===0) {
            setdetail1("");
            setdetail2("");
            setdetail3("");
            setdetail4("");
            setdetail5("");
            setdetail6("");

        }else if(val===2) {
            setdetail1("");
            setdetail2("clientMap__pin-details--active");
            setdetail3("");
            setdetail4("");
            setdetail5("");
            setdetail6("");

        }else if(val===3) {
            setdetail1("");
            setdetail2("");
            setdetail3("clientMap__pin-details--active");
            setdetail4("");
            setdetail5("");
            setdetail6("");

        }else if(val===4) {
            setdetail1("");
            setdetail2("");
            setdetail3("");
            setdetail4("clientMap__pin-details--active");
            setdetail5("");
            setdetail6("");

          
        }else if(val===5) {
            setdetail1("");
            setdetail2("");
            setdetail3("");
            setdetail4("");
            setdetail5("clientMap__pin-details--active");
            setdetail6("");

           

        }else if(val===6) {
            setdetail1("");
            setdetail2("");
            setdetail3("");
            setdetail4("");
            setdetail5("");
            setdetail6("clientMap__pin-details--active");


        }
    }
    return (
        <>
            <section className="clientMap">
                <div className="clientMap__text">
                    <Heading regular = "Our " bold = "Clients"/>

                    <div className="clientMap__text-content">
                        <p className="paragraph-extraLight" style= {{marginTop: "30px", marginBottom: "2.5rem"}}>
                        Sai Overseas (Hk) Ltd Is A Trading And <br/> Consulting Firm Registered In Honk Kong
                        </p>

                        <div className="clientMap__names">
                            <p className="paragraph-extraLight clientMap-name-1">Hong Kong</p>
                            <p className="paragraph-extraLight clientMap-name-2">Nepal</p>
                            <p className="paragraph-extraLight clientMap-name-3">India</p>
                            <p className="paragraph-extraLight clientMap-name-4">Nepal</p>
                            <p className="paragraph-extraLight clientMap-name-5">Hong Kong</p>
                        </div>
                    </div>
                </div>

                <div className="clientMap__map">
                    <img src={clientMapImg} alt="" className="clientMap__map-img" onClick={() => handleMouseOver(0)}/>
                    <div className="clientMap__pin-container" id = "pinNep"  onClick={() => handleMouseOver(1)}>
                        <div className={`clientMap__pin-details ${ detail1 }`}>
                            <p className="paragraph-color">Kathmandu, Nepal office</p>
                            <p className="paragraph-extraLight--white">
                                New road gate, Jayanti complex, <br/>
                                store no 67. LOOKS <br/>
                                Tel: +977-9810370771 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                    <div className="clientMap__pin-container" id = "pinInd"  onClick={() => handleMouseOver(2)}>
                        <div className={`clientMap__pin-details ${ detail2 }`}>
                            <p className="paragraph-color">Kathmandu, Nepal office</p>
                            <p className="paragraph-extraLight--white">
                                New road gate, Jayanti complex, <br/>
                                store no 67. LOOKS <br/>
                                Tel: +977-9810370771 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                    <div className="clientMap__pin-container" id = "pinHk"  onClick={() => handleMouseOver(3)}>
                        <div className={`clientMap__pin-details ${ detail3 }`}>
                            <p className="paragraph-color">Kathmandu, Nepal office</p>
                            <p className="paragraph-extraLight--white">
                                New road gate, Jayanti complex, <br/>
                                store no 67. LOOKS <br/>
                                Tel: +977-9810370771 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                    <div className="clientMap__pin-container" id = "pinHk2"  onClick={() => handleMouseOver(4)}>
                         <div className={`clientMap__pin-details ${ detail4 }`}>
                         <p className="paragraph-color">Guangzhou, China office</p>
                            <p className="paragraph-extraLight--white">
                                广州市越秀区麓苑路33号之二322 <br/>
                                store no 67. LOOKS <br/>
                                Tel: +86-020-83513398 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                    <div className="clientMap__pin-container" id = "pinInd2"  onClick={() => handleMouseOver(5)}>
                         <div className={`clientMap__pin-details ${ detail5 }`}>
                             <p className="paragraph-color">Guangzhou, China office</p>
                            <p className="paragraph-extraLight--white">
                                广州市越秀区麓苑路33号之二322 <br/>
                                store no 67. LOOKS <br/>
                                Tel: +86-020-83513398 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                    <div className="clientMap__pin-container" id = "pinHk3"  onClick={() => handleMouseOver(6)}>
                         <div className={`clientMap__pin-details ${ detail6 }`}>
                             <p className="paragraph-color">Guangzhou, China office</p>
                            <p className="paragraph-extraLight--white">
                                广州市越秀区麓苑路33号之二322 <br/>
                                store no 67. LOOKS <br/>
                                Tel: +86-020-83513398 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClientMap
