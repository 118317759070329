import React from 'react'
import Breadcrumbs from './Breadcrumbs'
import Navigation from './Navigation'

function Header2() {
    return (
        <>
            <Navigation/>
            <Breadcrumbs/>
        </>
    )
}

export default Header2
