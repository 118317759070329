import React, { useState, useEffect } from 'react'
import Button from '../components/Button';

import Footer from '../components/Footer'
import FooterContact from '../components/FooterContact'
import Header2 from '../components/Header2'

import pic1 from '../assets/img/pic3.png';
import pic2 from '../assets/img/pic2.png';


function Logistics() {

    const [ activeState, setActiveState ] = useState({
        activeObject: 0,
        objects: [{ label: "About Us" }, { label: "Customized agreement" }, { label: "Transparency" }, { label: "Customer-client Confidentiality" }, { label: "Project Sign Up" }]
    });

    const toggleActive = (index) => {

        setActiveState({ ...activeState, activeObject: activeState.objects[index] });
    }

    const toggleActiveStyles = (index) => {
        if(activeState.objects[index] === activeState.activeObject) {
            return "aboutDynDesc__btn aboutDynDesc__btn-active"
        } else {
            return "aboutDynDesc__btn"
        }
    }

    useEffect(() => {
        toggleActive(0);
    },activeState)

    return (
        <>
            <div className="pages">
                <Header2/>
                <section className="logistic">
                    <div className="aboutDynDesc__tabs logistic__btnTabs">
                        <div className="aboutDynDesc__btn-container">
                            {
                                activeState.objects.map((el,index) => (
                                    <div className="aboutDynDesc__btn-wrapper" key={index} onClick={() => {toggleActive(index)}}>
                                        <button className={toggleActiveStyles(index)}>
                                            {el.label}
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="logistic__content">
                        <div className="logistic__content-wrapper">
                            <div className="logistic__content-text logistic__content-text-1">
                                <div className="logistic__content-text--wrapper logistic__content-text--wrapper-1">
                                    <hr/>
                                    <p className="paragraph-bold" style = {{marginRight: "16px"}}>01</p>
                                    <div className="logistic__content-text--content">
                                        <p className="paragraph-bold" style = {{marginBottom: "30px"}}>HOME, KITCHEN AND ELECTRONIC APPLIANCES</p>
                                        <p className="paragraph-extraLight--white logistic__paraLight" style = {{marginBottom: "82px"}}>Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products</p>
                                        <Button label = "Get quote" addClass = "btn-white"/>
                                    </div>
                                </div>
                            </div>
                            <div className="logistic__content-imgBox">
                                <img src={pic1} alt="" className="logistic__content-img"/>
                            </div>
                        </div>
                        <div className="logistic__content-wrapper">
                            <div className="logistic__content-text logistic__content-text-2">
                                <div className="logistic__content-text--wrapper logistic__content-text--wrapper-2">
                                    <hr/>
                                    <p className="paragraph-bold" style = {{marginRight: "16px"}}>02</p>
                                    <div className="logistic__content-text--content">
                                        <p className="paragraph-bold" style = {{marginBottom: "30px"}}>HOME, KITCHEN AND ELECTRONIC APPLIANCES</p>
                                        <p className="paragraph-extraLight--white logistic__paraLight" style = {{marginBottom: "82px"}}>Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products</p>
                                        <Button label = "Get quote" addClass = "btn-white"/>
                                    </div>
                                </div>
                            </div>
                            <div className="logistic__content-imgBox">
                                <img src={pic2} alt="" className="logistic__content-img"/>
                            </div>
                        </div>
                        <div className="logistic__content-wrapper">
                            <div className="logistic__content-text logistic__content-text-1">
                                <div className="logistic__content-text--wrapper logistic__content-text--wrapper-1">
                                    <hr/>
                                    <p className="paragraph-bold" style = {{marginRight: "16px"}}>01</p>
                                    <div className="logistic__content-text--content">
                                        <p className="paragraph-bold" style = {{marginBottom: "30px"}}>HOME, KITCHEN AND ELECTRONIC APPLIANCES</p>
                                        <p className="paragraph-extraLight--white logistic__paraLight" style = {{marginBottom: "82px"}}>Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products</p>
                                        <Button label = "Get quote" addClass = "btn-white"/>
                                    </div>
                                </div>
                            </div>
                            <div className="logistic__content-imgBox">
                                <img src={pic1} alt="" className="logistic__content-img"/>
                            </div>
                        </div>
                        <div className="logistic__content-wrapper">
                            <div className="logistic__content-text logistic__content-text-2">
                                <div className="logistic__content-text--wrapper logistic__content-text--wrapper-2">
                                    <hr/>
                                    <p className="paragraph-bold" style = {{marginRight: "16px"}}>02</p>
                                    <div className="logistic__content-text--content">
                                        <p className="paragraph-bold" style = {{marginBottom: "30px"}}>HOME, KITCHEN AND ELECTRONIC APPLIANCES</p>
                                        <p className="paragraph-extraLight--white logistic__paraLight" style = {{marginBottom: "82px"}}>Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products</p>
                                        <Button label = "Get quote" addClass = "btn-white"/>
                                    </div>
                                </div>
                            </div>
                            <div className="logistic__content-imgBox">
                                <img src={pic2} alt="" className="logistic__content-img"/>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterContact/>
                <Footer/>
            </div>
        </>
    )
}

export default Logistics
