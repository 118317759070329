import React from 'react'
import DynamicDesc from '../components/DynamicDesc'
import Heading from '../components/Heading'

import ImgGallery from '../components/ImgGallery';
import FooterContact from '../components/FooterContact';
import Button from '../components/Button';
import Footer from '../components/Footer';
import ClientMap from '../components/ClientMap';
import Header from '../components/Header';





function Home() {
    return (
        <div className="page">

            <Header/>
            <DynamicDesc/>

            <section className="services">
                <div className="services__container">
                    <div className="services__desc">
                        <div className="services__desc-wrapper">
                            <Heading regular="Our " bold="Services" addClass="margin-bottom-small"/>
                            <p className="paragraph-extraLight">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong</p>
                        </div>

                        <Button label="Learn more"/>

                    </div>
                    <div className="services__content">
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.425" height="27.87" viewBox="0 0 32.425 27.87">
                                <path id="ic_flight_takeoff_24px" d="M2.9,28.242h30.38v3.2H2.9ZM34.187,13.276a2.4,2.4,0,0,0-2.942-1.695l-8.49,2.27L11.721,3.57l-3.086.815,6.62,11.464L7.308,17.976l-3.15-2.462-2.318.624L4.75,21.19l1.231,2.127,2.558-.688,8.49-2.27L23.985,18.5l8.49-2.27a2.434,2.434,0,0,0,1.711-2.958Z" transform="translate(-1.84 -3.57)" />
                            </svg>
                            <p className="services-label">Product/supplier sourcing and trading</p>
                            <p className="services-num">01</p>
                        </div>
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38.375" height="27.182" viewBox="0 0 38.375 27.182">
                                <path id="ic_laptop_chromebook_24px" d="M35.177,26.984V3H3.2V26.984H0v3.2H38.375v-3.2Zm-12.792,0h-6.4v-1.6h6.4Zm9.594-4.8H6.4V6.2H31.979Z" transform="translate(0 -3)" />
                            </svg>
                            <p className="services-label">Supplier negotiation</p>
                            <p className="services-num">02</p>
                        </div>
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.984" height="23.984" viewBox="0 0 23.984 23.984">
                                <path id="ic_call_made_24px" d="M11.995,5V8.2H22.532L4,26.73l2.255,2.255L24.786,10.452V20.989h3.2V5Z" transform="translate(-4 -5)" />
                            </svg>
                            <p className="services-label">Factory and pre-shipment goods inspection</p>
                            <p className="services-num">03</p>
                        </div>
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.781" height="31.979" viewBox="0 0 28.781 31.979">
                                <path id="ic_today_24px" d="M28.583,4.2h-1.6V1h-3.2V4.2H10.995V1H7.8V4.2H6.2A3.183,3.183,0,0,0,3.016,7.4L3,29.781a3.2,3.2,0,0,0,3.2,3.2H28.583a3.207,3.207,0,0,0,3.2-3.2V7.4a3.207,3.207,0,0,0-3.2-3.2Zm0,25.583H6.2V12.193H28.583ZM9.4,15.39H17.39v7.995H9.4Z" transform="translate(-3 -1)"/>
                            </svg>
                            <p className="services-label">China customs clearance</p>
                            <p className="services-num">04</p>
                        </div>
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28.756" height="28.784" viewBox="0 0 28.756 28.784">
                                <path id="ic_power_settings_new_24px" d="M18.98,3h-3.2V18.989h3.2ZM26.7,6.47,24.433,8.74a11.065,11.065,0,0,1,4.141,8.65,11.193,11.193,0,1,1-18.26-8.666L8.059,6.47a14.391,14.391,0,1,0,18.644,0Z" transform="translate(-3.016 -3)"/>
                            </svg>
                            <p className="services-label">Inbound and outbound logistics management services</p>
                            <p className="services-num">05</p>
                        </div>
                        <div className="services__content-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.177" height="30.348" viewBox="0 0 35.177 30.348">
                                <path id="ic_shopping_basket_24px" d="M26.919,13.181l-7-10.489a1.588,1.588,0,0,0-1.327-.672,1.562,1.562,0,0,0-1.327.688l-7,10.473H2.6A1.6,1.6,0,0,0,1,14.78a1.346,1.346,0,0,0,.064.432L5.125,30.034A3.18,3.18,0,0,0,8.2,32.368H28.982a3.214,3.214,0,0,0,3.086-2.334l4.061-14.822.048-.432a1.6,1.6,0,0,0-1.6-1.6Zm-13.127,0,4.8-7.035,4.8,7.035Zm4.8,12.792a3.2,3.2,0,1,1,3.2-3.2A3.2,3.2,0,0,1,18.588,25.972Z" transform="translate(-1 -2.02)" />
                            </svg>
                            <p className="services-label">Warehousing services in China</p>
                            <p className="services-num">06</p>
                        </div>
                    </div>
                </div>
            </section>
            
            <ImgGallery/>
            <ClientMap/>
            <FooterContact/>
            <Footer/>
        </div>
    )
}

export default Home
