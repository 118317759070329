import React from 'react'
import Footer from '../components/Footer'
import FooterContact from '../components/FooterContact'
import Heading from '../components/Heading'
import Navigation from '../components/Navigation'
import Breadcrumbs from '../components/Breadcrumbs';
import Header2 from './../components/Header2';

function Contact() {
    return (
        <>
            <div className="pages">
            <Header2/>

                <section className="contact">
                    <div className="contact__container">
                        <Heading regular = "Our " bold = "Locations" addClass = "margin-bottom-bAvg"/>
                        <p className="paragraph-extraLight margin-bottom-medium">
                            Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And Logistics Service Providers In China We Have Team Members Across China, malaysia, Indonesia And Vietnam in Search Of quality And Innovative Products
                        </p>
                        <div className="contact__locations">
                            <div className="contact__location">
                                <p className="paragraph-color">KATHMANDU, NEPAL OFFICE</p>
                                <p className="paragraph-extraLight">
                                    New road gate, Jayanti complex,<br/>
                                    store no 67. LOOKS<br/>
                                    Tel: +977-9810370771<br/>
                                    Tel: +977-9841756687
                                </p>
                            </div>
                            <div className="contact__location">
                                <p className="paragraph-color">Guangzhou, China office</p>
                                <p className="paragraph-extraLight">
                                    广州市越秀区麓苑路33号之二322<br/>
                                    store no 67. LOOKS<br/>
                                    Tel: +86-020-83513398<br/>
                                    Tel: +977-9841756687
                                </p>
                            </div>
                            <div className="contact__location">
                                <p className="paragraph-color">Sydney, Australia office</p>
                                <p className="paragraph-extraLight">
                                    New road gate, Jayanti complex,<br/>
                                    store no 67. LOOKS<br/>
                                    Tel: +977-9810370771<br/>
                                    Tel: +977-9841756687
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterContact/>
                <Footer/>
            </div>
        </>
    )
}

export default Contact
