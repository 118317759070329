import React from 'react'
import Button from './Button'

function Teammate(props) {
    return (
        <>
            <div className="teammate">
                <img src={props.img} alt="" className="teammate__img"/>
                <p className="teammate-name">{props.name}</p>
                <p className="teammate-desig" style = {{marginBottom:"18.4px"}}>{props.desig}</p>
                <div className="teammate__social margin-bottom-bAvg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.073" height="16.404" viewBox="0 0 9.073 16.404">
                        <path id="Path_35" data-name="Path 35" d="M35.635,123.76c.54-.011,1.08,0,1.62,0h.228v-2.647c-.288-.023-.588-.068-.888-.079-.552-.023-1.1-.045-1.656-.034a4.258,4.258,0,0,0-2.34.679,3.279,3.279,0,0,0-1.4,2.206,7.2,7.2,0,0,0-.1,1.131c-.012.588,0,1.188,0,1.776v.226H28.41v2.952h2.664V137.4h3.264v-7.42H37c.132-.984.264-1.957.408-2.964h-3.1s.012-1.459.024-2.1C34.338,124.054,34.891,123.783,35.635,123.76Z" transform="translate(-28.41 -120.998)" fill-rule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.969" height="12.81" viewBox="0 0 16.969 12.81">
                        <path id="Path_37" data-name="Path 37" d="M59.232,132.71a3.454,3.454,0,0,1-3.24-2.249,3.786,3.786,0,0,0,1.5-.045c.012,0,.024-.011.048-.022a3.509,3.509,0,0,1-2.268-1.481,3.028,3.028,0,0,1-.516-1.725,3.608,3.608,0,0,0,1.548.39,3.232,3.232,0,0,1-1.44-1.959,3.012,3.012,0,0,1,.372-2.349,10.293,10.293,0,0,0,7.189,3.384c-.024-.122-.036-.245-.06-.356a3.022,3.022,0,0,1,.5-2.115,3.311,3.311,0,0,1,2.364-1.458,3.558,3.558,0,0,1,3.048.9.188.188,0,0,0,.18.056,7.165,7.165,0,0,0,2.028-.724c.012-.011.036-.011.048-.022h.024a3.345,3.345,0,0,1-1.488,1.759,6.9,6.9,0,0,0,1.944-.49l.012.011c-.132.167-.264.323-.4.49a6.528,6.528,0,0,1-1.272,1.113.115.115,0,0,0-.06.1,8.22,8.22,0,0,1-.06,1.436,8.9,8.9,0,0,1-.9,2.883,9.361,9.361,0,0,1-1.884,2.549,9.663,9.663,0,0,1-4.788,2.482,10.275,10.275,0,0,1-1.944.212,10.438,10.438,0,0,1-5.58-1.391.705.705,0,0,1-.084-.044,7.572,7.572,0,0,0,3.492-.445A6.3,6.3,0,0,0,59.232,132.71Z" transform="translate(-54.06 -122.676)" fill-rule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.603" height="9.935" viewBox="0 0 15.603 9.935">
                        <path id="Path_55" data-name="Path 55" d="M194.688,133.369a1.749,1.749,0,0,1-1.2-1.058c-.516-1.3-.672-6.716.324-7.785a2.03,2.03,0,0,1,1.308-.632c2.688-.262,11-.229,11.965.087a1.8,1.8,0,0,1,1.176,1.025c.564,1.341.588,6.215-.072,7.5a1.732,1.732,0,0,1-.84.774C206.329,133.762,196.008,133.762,194.688,133.369Zm4.272-2.54c1.62-.763,3.216-1.516,4.848-2.279-1.632-.774-3.228-1.526-4.848-2.3Z" transform="translate(-193.081 -123.719)" fill-rule="evenodd"/>
                    </svg>
                </div>
                <Button label = "Read bio"/>
            </div>
        </>
    )
}

export default Teammate
