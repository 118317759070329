import React from 'react'
import Navigation from './Navigation'

import ctaIcon from '../assets/img/icons/cta.png'
import headerImg from '../assets/img/1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
function Header() {

    SwiperCore.use([Autoplay, EffectFade, Pagination]);

    var menu = ['01', '02', '03'];
    var menu2 = ['Slide ekk', 'Slide dui', 'Slide teen'];

    return (
        <>
            <header className="header">
                <Navigation/>
                <div className="header__wrapper">
                
                <Swiper
                    effect="fade"
                    loop = {true}
                    slidesPerView = {1}
                    // spaceBetween= {0}
                    direction = {"vertical"}
                    autoplay= {
                    {delay: 2500,
                    disableOnInteraction: false,
                        }
                   }
                   pagination={{ 
                       clickable: true ,
                       el: '.swiper-pagination.pagination-num, .swiper-pagination.pagination-bull',
                       renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (menu[index]) + '</span>';
                      },
                      
                    }}
                   height= {536}
                   loop= {false}
                   speed= {3000}
                   fadeEffect = {
                       {
                           crossFade:  true
                       }
                   }
                    >
                        <SwiperSlide>
                            <div className="header__container">
                                <div className="header__text">
                                    <h1 className="heading-large" style={{marginBottom: "19px"}}>One-stop <br/> Import <br/> Solution</h1>
                                    <div className="header__text-wrapper">
                                        <hr/>
                                        <div className="header__text-wrapper2">
                                        <p className="paragraph-medium header__paragraph">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And </p>
                                        <button className="btn header__cta"><img src={ctaIcon} alt="" className="header__ctaBtn"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="header__imgBox">
                                    <img src={headerImg} alt="" className="header__img"/>
                                </div>
                                {/* <div className="header__pagination">
                                    <p className="header__pagination-num--active">01</p>
                                    <p className="header__pagination-num">02</p>
                                    <p className="header__pagination-num">03</p>
                                </div> */}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="header__container">
                                <div className="header__text">
                                    <h1 className="heading-large" style={{marginBottom: "19px"}}>Two-stop <br/> Import <br/> Solution</h1>
                                    <div className="header__text-wrapper">
                                        <hr/>
                                        <div className="header__text-wrapper2">
                                        <p className="paragraph-medium header__paragraph">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And </p>
                                        <button className="btn header__cta"><img src={ctaIcon} alt="" className="header__ctaBtn"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="header__imgBox">
                                    <img src={headerImg} alt="" className="header__img"/>
                                </div>
                                {/* <div className="header__pagination">
                                    <p className="header__pagination-num--active">02</p>
                                    <p className="header__pagination-num">03</p>
                                    <p className="header__pagination-num">01</p>
                                </div> */}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="header__container">
                                <div className="header__text">
                                    <h1 className="heading-large" style={{marginBottom: "19px"}}>Three-stop <br/> Import <br/> Solution</h1>
                                    <div className="header__text-wrapper">
                                        <hr/>
                                        <div className="header__text-wrapper2">
                                        <p className="paragraph-medium header__paragraph">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work In Close Collaboration With Top notch Factories And </p>
                                        <button className="btn header__cta"><img src={ctaIcon} alt="" className="header__ctaBtn"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="header__imgBox">
                                    <img src={headerImg} alt="" className="header__img"/>
                                </div>
                                {/* <div className="header__pagination">
                                    <p className="header__pagination-num--active">03</p>
                                    <p className="header__pagination-num">01</p>
                                    <p className="header__pagination-num">02</p>
                                </div> */}
                            </div>
                        </SwiperSlide>
                        <div className="swiper-pagination pagination-num"></div>
                        <div className="swiper-pagination pagination-bull"></div>
                    </Swiper>
                </div>
                
            </header>
        </>
    )
}

export default Header
