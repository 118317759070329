import React from 'react'

import footerLogo from '../assets/img/logo/logoW.png';
import fbLogo from '../assets/img/icons/fbw.png';
import twLogo from '../assets/img/icons/tww.png';
import ytLogo from '../assets/img/icons/ytw.png';
import dividerLogo from '../assets/img/logo.png';




function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="footer__container">
                    <div className="footer__logos">
                        <img src={footerLogo} alt="" className="footer__logo"/>
                        <div className="footer__social">
                            <img src={fbLogo} alt="" className="footer__social-icon"/>
                            <img src={twLogo} alt="" className="footer__social-icon"/>
                            <img src={ytLogo} alt="" className="footer__social-icon"/>
                        </div>
                    </div>

                    <div className="footer__content">
                        <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And <br/> Consulting Firm Registered In Honk Kong </p>

                        <div className="footer__social-2">
                            <img src={fbLogo} alt="" className="footer__social-icon"/>
                            <img src={twLogo} alt="" className="footer__social-icon"/>
                            <img src={ytLogo} alt="" className="footer__social-icon"/>
                        </div>

                        <div className="footer__contact">
                            <p className="paragraph-color">Kathmandu, Nepal office</p>
                            <p className="paragraph-extraLight--white">
                                New road gate, Jayanti complex, <br/>
                                store no 67. LOOKS <br/>
                                Tel: +977-9810370771 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                        <div className="footer__contact">
                            <p className="paragraph-color">Guangzhou, China office</p>
                            <p className="paragraph-extraLight--white">
                                广州市越秀区麓苑路33号之二322 <br/>
                                store no 67. LOOKS <br/>
                                Tel: +86-020-83513398 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                        <div className="footer__contact">
                            <p className="paragraph-color">Sydney, Australia office</p>
                            <p className="paragraph-extraLight--white">
                                New road gate, Jayanti complex, <br/>
                                store no 67. LOOKS <br/>
                                Tel: +977-9810370771 <br/>
                                Tel: +977-9841756687 <br/>
                            </p>
                        </div>
                    </div>

                    <div className="footer__divider">
                        <hr/>
                        <img src={dividerLogo} alt="" className="footer__divider-logo"/>
                        <hr/>
                    </div>

                    <div className="footer__copy">
                        <p>
                            <span className="paragraph-extraLight--white">COPYRIGHT 2021 </span>
                            <span className="paragraph-bold">SAI OVERSEAS</span>
                        </p>
                        <p>
                            <span className="footer-text">DESIGNED BY </span>
                            <span className="paragraph-bold">PIXEL CREATIVES</span>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
