import React from 'react'
import ClientMap from '../components/ClientMap'
import Footer from '../components/Footer'
import FooterContact from '../components/FooterContact'
import Heading from '../components/Heading'
import Navigation from '../components/Navigation'
import Teammate from '../components/Teammate'
import AboutDynDesc from './../components/AboutDynDesc';
import teamImg from '../assets/img/port.png'
import Breadcrumbs from '../components/Breadcrumbs';
import Header2 from '../components/Header2'

function About() {
    return (
        <>
            <div className="pages">
                <Header2/>

                <section className="about">
                    <AboutDynDesc/>
                    <ClientMap/>

                    <div className="about__team">
                        <div className="about__team-text">
                            <Heading regular = "Our" bold = " Team" addClass = "margin-bottom-bAvg"/>
                            <p className="paragraph-extraLight">
                                Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) 
                            </p>
                        </div>
                        <div className="about__teammates">
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                            <Teammate name = "Rajesh Shrestha" desig = "Senior Executive" img = {teamImg}/>
                        </div>
                    </div>
                </section>
                <FooterContact/>
                <Footer/>
            </div>
        </>
    )
}

export default About
