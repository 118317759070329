import React from 'react'
import Heading from './Heading';
import Button from './Button';

import dots from '../assets/img/dots.png'
// import logos from '../assets/img/clientele.png'
import logo1 from '../assets/img/icons/clientele/g1.png';
import logo2 from '../assets/img/icons/clientele/g2.png';
import logo3 from '../assets/img/icons/clientele/g3.png';
import logo4 from '../assets/img/icons/clientele/g4.png';


import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

function FooterContact() {
    SwiperCore.use([Autoplay, Pagination]);

    return (
        <>
            <section className="footerContact">
                <div className="footerContact__container">
                    <div className="footerContact__form-wrapper">
                        <Heading regular = "Get " bold = "a quote" addClass = "margin-bottom-medium"/>
                        <form autoComplete= "off" className= "footerContact__form" id="contact-form">
                            <label for="fname">Full Name</label>
                            <input type="text" id="fname" name="fname" required/>
                            <label for="email">Email</label>
                            <input type="email" id="email" name="email" required/>
                            <label for="cName">Company</label>
                            <input type="text" id="cName" name="cName" required/>
                            <label for="num">Phone</label>
                            <input type="text" id="num" name="num" pattern="[789][0-9]{9}" required/>
                            <label for="message">please tell us how we can help you</label>
                            <input type="text" id="message" name="message" required/>
                           
                            {/* <label htmlFor="subscribe" className="footerContact__checkBox-wrapper" style={{marginBottom: "2.1rem"}}>
                                <input type="checkbox" id="subscribe" className="footerContact__checkBox"/>
                                <span className= "footerContact-subscribe" >Subscribe to our newsletter</span>
                            </label> */}
                            <div className="footerContact__btnWrapper">
                                <Button label="Submit"/>
                                <p className="footerContact__call">Question? You can call <br/> us at +7(88) 9865 1245</p>
                            </div>
                        </form>
                    </div>
                    <div className="footerContact__wrapper">
                        <Heading regular = "Trusted " bold = "Clients" addClass = "heading__wrapper-white margin-bottom-avg"/>

                        <div className="footerContact__clientele">
                            <div className="footerContact__clientele-container">
                            <Swiper
                                // effect="fade"
                                loop = {true}
                                slidesPerView = {2}
                                spaceBetween= {31}
                                // direction = {"vertical"}
                                autoplay= {
                                {delay: 2500,
                                disableOnInteraction: false,
                                    }
                            }
                            pagination={{ 
                                clickable: true ,
                                el: '.swiper-pagination.clientele-pagination',
                                
                                
                                }}
                            // width= {"90%"}
                            loop= {false}
                            speed= {2500}
                            // fadeEffect = {
                            //     {
                            //         crossFade:  true
                            //     }
                            // }
                                >
                                <SwiperSlide>
                                    <div className="footerContact__clientele-content">
                                        <div className="footerContact__clientele-wrapper">
                                            <p className="footerContact__clientele-quote">"</p>
                                            <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work</p>
                                        </div>
                                        <div className="footerContact__clientele-writer">
                                            <p className="paragraph-bold">JACK MA</p>
                                            <p className="paragraph-extraLight--white">Alibaba Group</p>

                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="footerContact__clientele-content">
                                <div className="footerContact__clientele-wrapper">
                                        <p className="footerContact__clientele-quote">"</p>
                                        <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work</p>
                                    </div>
                                    <div className="footerContact__clientele-writer">
                                        <p className="paragraph-bold">JACK MA</p>
                                        <p className="paragraph-extraLight--white">Alibaba Group</p>

                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="footerContact__clientele-content">
                                <div className="footerContact__clientele-wrapper">
                                        <p className="footerContact__clientele-quote">"</p>
                                        <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work</p>
                                    </div>
                                    <div className="footerContact__clientele-writer">
                                        <p className="paragraph-bold">JACK MA</p>
                                        <p className="paragraph-extraLight--white">Alibaba Group</p>

                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="footerContact__clientele-content">
                                <div className="footerContact__clientele-wrapper">
                                        <p className="footerContact__clientele-quote">"</p>
                                        <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work</p>
                                    </div>
                                    <div className="footerContact__clientele-writer">
                                        <p className="paragraph-bold">JACK MA</p>
                                        <p className="paragraph-extraLight--white">Alibaba Group</p>

                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="footerContact__clientele-content">
                                <div className="footerContact__clientele-wrapper">
                                        <p className="footerContact__clientele-quote">"</p>
                                        <p className="paragraph-extraLight--white">Sai Overseas (Hk) Ltd Is A Trading And Consulting Firm Registered In Honk Kong Since 2013 Our Sister Concern Companiies Are in Operation At Guangzhou (China) and Nepal We Work</p>
                                    </div>
                                    <div className="footerContact__clientele-writer">
                                        <p className="paragraph-bold">JACK MA</p>
                                        <p className="paragraph-extraLight--white">Alibaba Group</p>

                                    </div>
                                </div>
                                </SwiperSlide>
                                
                                <div className="swiper-pagination clientele-pagination"></div>
                                </Swiper>
                            </div>

                            <div className="footerContact__clientele-logos">
                            <Swiper
                                loop = {true}
                                slidesPerView = {5}
                                spaceBetween= {31}
                                autoplay= {
                                {delay: 2500,
                                disableOnInteraction: false,
                                    }
                            }
                           
                            loop= {false}
                            speed= {2500}
                                >
                                <SwiperSlide>
                                    <img src={logo1} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo2} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo3} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo4} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo1} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo2} alt=""/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={logo3} alt=""/>
                                </SwiperSlide>
                            </Swiper>
                            </div>
                        </div>
                        
                        <div className="footerContact__newsletter">
                            <p className="paragraph-extraLight" style={{marginRight:"72px"}}>Sign up to our list for latest updates <br/> on our products and services</p>
                            
                            <input type="email" placeholder="Your email address" style={{marginRight:"20px"}}/>

                            <Button label="Sign Up"/>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FooterContact
