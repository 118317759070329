import React, { useState, useEffect } from 'react'

import bgImg from '../assets/img/bgImg.png';
import bgImg2 from '../assets/img/pic1.png';
import bgImg3 from '../assets/img/pic2.png';
import bgImg4 from '../assets/img/pic3.png';
import bgImg5 from '../assets/img/pic4.png';


import Heading from './Heading';

function ImgGallery() {

    const [ activeState, setActiveState ] = useState({
        activeObject: 0,
        objects: [
            { label: "HOME, KITCHEN AND ELECTRONIC APPLIANCES" }, 
            { label: "CORPORATE GIFT PRODUCTS" }, 
            { label: "AUTOMOBILE SPARE PARTS" }, 
            { label: "POWER TOOLS AND MACHINERIES" }, 
            { label: "GYM EQIUPMENTS" },
            { label: "FURNITURE AND FIXTURES (HOME+OFFICE)" },
            { label: "MOBILES AND ACCESSORIES" },
            { label: "HARDWARE AND SANITARY WARES" },
            { label: "YARN, TEXTILES AND READYMADE GARMENTS" },

        ]
    });

    const [ bgImgFile, setBgImgFile ] = useState(bgImg);
    
    const toggleImg = (label) => {

        if(label=="HOME, KITCHEN AND ELECTRONIC APPLIANCES"){
            setBgImgFile(bgImg)
        }else if(label=="CORPORATE GIFT PRODUCTS"){
            setBgImgFile(bgImg2)
        }else if(label=="AUTOMOBILE SPARE PARTS"){
            setBgImgFile(bgImg3)
        }else if(label=="POWER TOOLS AND MACHINERIES"){
            setBgImgFile(bgImg4)
        }else if(label=="GYM EQIUPMENTS"){
            setBgImgFile(bgImg5)
        }else if(label=="FURNITURE AND FIXTURES (HOME+OFFICE)"){
            setBgImgFile(bgImg3)
        }else if(label=="MOBILES AND ACCESSORIES"){
            setBgImgFile(bgImg)
        }else if(label=="HARDWARE AND SANITARY WARES"){
            setBgImgFile(bgImg2)
        }else if(label=="YARN, TEXTILES AND READYMADE GARMENTS"){
            setBgImgFile(bgImg4)
        }
       
    }

    const toggleActive = (index) => {

        setActiveState({ ...activeState, activeObject: activeState.objects[index] });

       

    }

    const toggleActiveStyles = (index) => {
        if(activeState.objects[index] === activeState.activeObject) {
            return "gallery__btn gallery__btn-active"
        } else {
            return "gallery__btn"
        }
    }

    useEffect(() => {
        toggleActive(0);
    },activeState)

    useEffect(() => {
        toggleImg(activeState.activeObject.label)
    },[activeState.activeObject])


    return (
        <>
            <section className="gallery">
                <img src={bgImgFile} alt="" className="gallery__img"/>
                
                <div className="gallery__container">
                    <Heading regular="Corporate " bold= "Gifting" addClass = "heading__wrapper-white margin-bottom-avg"/>

                    <div className="gallery__btnWrapper">
                        {
                            activeState.objects.map((el,index) => (
                                <button className={toggleActiveStyles(index)} key={index} onClick={() => {toggleActive(index)}}>
                                    <span className="gallery__btn-num">0{index+1}</span>
                                    <span className="gallery__btn-text">{el.label}</span>
                                    <hr/>
                                </button>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ImgGallery
