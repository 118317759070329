import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import "./styles/App.scss"
import Home from './pages/Home';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Logistics from './pages/Logistics';
import Services from './pages/Services';
import About from './pages/About';
import Test from './pages/Test';

function App() {
  return (
    <div className="container">
       <Router >

            <Switch>
                <Route path='/about' exact component = {About}/>    
                <Route path='/contact' exact component = {Contact}/>
                <Route path='/faq' exact component = {Faq}/>
                <Route path='/logistic' exact component = {Logistics}/>
                <Route path='/services' exact component = {Services}/>
                <Route path='/test' exact component = {Test}/>

                <Route path='/' exact component = {Home}/>
            </Switch>


        </Router>
    </div>
  );
}

export default App;
